
import { defineComponent, onBeforeMount } from 'vue'
import router from './router'
import zhCN from 'ant-design-vue/es/locale/zh_CN'

export default defineComponent({
  setup() {
    // router.push('/intention/intenlist')
    const IsPC = () => {
      var userAgentInfo = navigator.userAgent
      var Agents = [
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod',
      ]
      var flag = true
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false
          break
        }
      }
      return flag
    }
    onBeforeMount(() => {
      console.log(IsPC())
      if (!IsPC()) location.replace('http://m.sem.qindiankeji.cn/')
    })
    return {
      zhCN,
    }
  },
})
