import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/home/Home.vue'

export const staticRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '秦点官网',
    },
  },
]

export const allRoutes = {}

export const routes: Array<RouteRecordRaw> = [...staticRoutes]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
