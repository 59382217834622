import { ref } from 'vue'

export default function indexFn(): unknown {
  const popUPActive = ref(false)
  const popUPChange = ref(0)
  const tabNav = ref([
    { id: 1, text: '工具' },
    { id: 2, text: '金融' },
    { id: 3, text: '社交' },
    { id: 4, text: '游戏' },
    { id: 5, text: '生活' },
    { id: 6, text: '阅读' },
  ])
  const tabInfo = ref([
    {
      id: 1,
      title: '工具',
      subhead: [
        { id: 1, text: '增加有效曝光' },
        { id: 2, text: '提升注册人数' },
      ],
      msg: {
        tactic: 'CPC出价1.5~2元，关注用户使用场景，匹配核心行业词和竞品词',
        effect:
          'CPA均价2~3元，安装转化率70%+，注册率50%+，有效注册量100%达标，比客户预期成本降低30%',
      },
    },
    {
      id: 2,
      title: '金融',
      subhead: [
        { id: 1, text: '提升品牌知名度' },
        { id: 2, text: '降低付费授信成本' },
      ],
      msg: {
        tactic:
          'CPC出价3~5元，结合用户需求场景，品牌保护之外，匹配核心行业词、竞品词和人群词',
        effect: 'CPA均价5~7元，安装转化率75%+，授信成本下降20%',
      },
    },
    {
      id: 3,
      title: '社交',
      subhead: [
        { id: 1, text: '提升注册和付费人数' },
        { id: 2, text: '增加有效曝光' },
      ],
      msg: {
        tactic: 'CPC出价3~4元，限制投放时间以及地区定向，专注于精准人群',
        effect:
          'CPA均价6~8元，安装转化率50%+，有效注册数超出客户预期15%，ROI100%达成目标',
      },
    },
    {
      id: 4,
      title: '游戏',
      subhead: [
        { id: 1, text: '增加平均每用户付费值（Arpu）' },
        { id: 2, text: '提高用户注册数' },
      ],
      msg: {
        tactic: '根据游戏属性分析用户画像，只面向目标用户投放',
        effect:
          'CPA均价15-25元，日注册数超额10%完成客户需求，平均每用户付费值比预期高出30%且用户后续活跃度高，有效提高游戏生态环境。',
      },
    },
    {
      id: 5,
      title: '生活',
      subhead: [{ id: 1, text: '提高新用户激活数' }],
      msg: {
        tactic:
          '新老用户分开投放，分别制定不同出价策略，产品不同功能使用不同素材面向对应用户',
        effect:
          '新客CPA均价2-4元，老客维护CPA均价1.5-3元，平均用户激活率为60%-70%，比一般用户提高15%，每日有效用户获取量100%完成客户需求。',
      },
    },
    {
      id: 6,
      title: '阅读',
      subhead: [
        { id: 1, text: '提高用户阅读率、留存率' },
        { id: 2, text: '增加用户获取' },
      ],
      msg: {
        tactic:
          '根据用户阅读兴趣进行分类、分时投放，开启模糊匹配持续探索带量词',
        effect:
          ' CPA均价5-8元，用户获取数130%完成客户需求且不断持续增长，用户7留率达到40%以上，超出客户预期15%。',
      },
    },
  ])
  const optCard = ref([
    {
      id: 1,
      title: '服务',
      info: '全行业视角、标准化流程，甲方思维',
    },
    {
      id: 2,
      title: '优化',
      info: '名企增长专家把控，高级优化师操刀',
    },
    {
      id: 3,
      title: '渠道',
      info: '优质渠道资源整合，强大防作弊系统',
    },
    {
      id: 4,
      title: '媒体',
      info: '商店资源全覆盖，专属沟通渠道触达',
    },
  ])

  const setPUA = function (popVal: boolean) {
    popUPActive.value = popVal
  }
  const setPUAChange = function (popNum: number) {
    popUPChange.value = popNum
  }
  return {
    popUPActive,
    popUPChange,
    tabNav,
    tabInfo,
    optCard,
    setPUA,
    setPUAChange,
  }
}
