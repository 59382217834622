import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './assets/reset.less'
import './permission'
import { routes } from './router'

// 导入全局样式
import '@/styles/less/common.less'

import { createFromIconfontCN } from '@ant-design/icons-vue'

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2696402_c1xj1ew5tf.js',
})

// 设置网页 title keyword des
document.title =
  '秦点科技-专业移动产品增长服务平台-ASO优化-ASA优化' ||
  '秦点科技-专业移动产品增长服务平台-ASO优化-ASA优化'

document
  .querySelector('meta[name="keywords"]')
  ?.setAttribute('content', 'ASA,关键词优化,秦点科技,App推广,ASO,App,秦点')

document
  .querySelector('meta[name="description"]')
  ?.setAttribute(
    'content',
    '秦点科技是国内专业的移动产品增长服务平台，覆盖AppStore&安卓商店双平台，资深的优化师团队为您提供专业的App推广方案以及贴心的服务。自研的ASA智投系统、ASO关键词优化方案助力用户增长。'
  )

createApp(App)
  .use(store)
  .use(Antd)
  .use(router)
  .component('IconFont', IconFont)
  .mount('#app')
